import React from 'react';

import { useQueryContext, useMediaQuery } from 'hooks';
import { MediaQueries, Icons, Colors } from 'environment';
import { meetTheTeam } from 'data/about-us.json';
import {
    Title,
    Container,
    Content,
    TeamContainer,
    TeamMember,
    TeamMemberNextPlaceholder,
    Position,
    PictureContainer,
    Picture,
    PictureCircle,
    Name,
    CirclesContainer,
    LinkToCareers,
    BorderSVG
} from './MeetTheTeam.style';
import { BackgroundImage } from 'components/ui';

const TEAM_COLORS = [Colors.magenta, Colors.green, Colors.orange];

export function MeetTheTeam() {
    const {
        images: {
            aboutUs: {
                meetTheTeam: { waves }
            }
        }
    } = useQueryContext();

    const isTablet = useMediaQuery(MediaQueries.tablet);
    const isDesktop = useMediaQuery(MediaQueries.desktop);

    return (
        <Container>
            <BackgroundImage
                scaleX={1.05}
                position="relative"
                scaleTablet={2.5}
                mobile={waves.mobile}
                tablet={waves.desktop}
                desktop={waves.desktop}
                alt={meetTheTeam.waves.alt}
            />
            <CirclesContainer>
                {isDesktop && <Icons.CirclesLeft />}
                {isDesktop && <Icons.CirclesRight />}
                {isTablet && <Icons.CirclesRight />}
            </CirclesContainer>
            <Content>
                <Title>{meetTheTeam.title}</Title>

                <TeamContainer>
                    {meetTheTeam.members.map((member, index) => {
                        const computedColorIndex = (index + Math.floor(index / 3)) % 3;
                        return (
                            <TeamMember key={`member-${index}`}>
                                <PictureContainer>
                                    <Picture
                                        src={`/images/${member.image.src}`}
                                        alt={member.image.alt}
                                    />
                                    <PictureCircle bgColor={TEAM_COLORS[computedColorIndex]} />
                                </PictureContainer>
                                <Name>{member.name}</Name>
                                <Position>{member.position}</Position>
                            </TeamMember>
                        );
                    })}
                    <TeamMemberNextPlaceholder>
                        <BorderSVG src="/images/next-sensidev-border.svg" />
                        <LinkToCareers to="/careers#jobs">
                            {meetTheTeam.placeholderNext}
                        </LinkToCareers>
                    </TeamMemberNextPlaceholder>
                </TeamContainer>
            </Content>
        </Container>
    );
}
