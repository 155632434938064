import React, { useEffect } from 'react';

import { Colors } from 'environment';
import { computeBezierCurve } from 'helpers';
import { useMeasure } from 'hooks';

import { Container, Content, Text } from './Branch.phone.style';

interface Props {
    index: number;
    length: number;
    item: { time: string; text: string };
    top: number;
    onHeightChange: (height: number) => void;
}

export function Branch({ index, item, length, top, onHeightChange }: Props) {
    const [ref, bounds] = useMeasure<HTMLDivElement>();

    useEffect(() => {
        if (bounds.height) {
            onHeightChange(bounds.height);
        }
    }, [bounds.height]);

    let d = `M88,12 L74,12 ${computeBezierCurve(78, 12, 3, 85, true)}`;

    if (index !== length - 1) {
        d = `${d} l0,${bounds.height + 20}`;
    }

    return (
        <Container
            style={{
                top
            }}
        >
            <svg height={bounds.height + 100} width={100}>
                <path d={d} strokeWidth={4} />
                <circle
                    cx={index % 2 === 0 ? 88 : 92}
                    cy={12}
                    r={7.5}
                    fill={Colors.violet}
                    stroke={Colors.green}
                    strokeWidth={4}
                />
            </svg>
            <Content ref={ref} style={{ left: 110 }}>
                <h3>{item.time}</h3>
                <Text>{item.text}</Text>
            </Content>
        </Container>
    );
}
