import styled from 'styled-components';

import { Colors, MediaQueries } from 'environment';
import {
    DESKTOP_GRID_SIZE,
    TABLET_GRID_SIZE,
    DESKTOP_GRID_COLUMN,
    DESKTOP_GRID_SPACING
} from 'consts';

export const Container = styled.section`
    display: flex;
    justify-content: center;
`;

export const Content = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    color: ${Colors.violet};

    @media ${MediaQueries.desktop} {
        max-width: ${DESKTOP_GRID_SIZE}rem;
        padding: 12rem 2rem 4rem 0rem;
        margin: 0 2rem;
    }

    @media ${MediaQueries.tablet} {
        max-width: ${TABLET_GRID_SIZE}rem;
        padding-top: 12rem;
        margin: 0 2rem;
    }

    @media ${MediaQueries.phone} {
        padding: 6rem 2rem 2rem 2rem;
    }
`;

export const TextContainer = styled.div`
    @media ${MediaQueries.desktop} {
        max-width: ${DESKTOP_GRID_COLUMN * 10 + DESKTOP_GRID_SPACING * 9}rem;
    }

    @media ${MediaQueries.phone} {
        text-align: center;
    }
`;

export const Title = styled.h3`
    padding-bottom: 2rem;
`;

export const Description = styled.h4`
    line-height: 3.8rem;
    padding-bottom: 12rem;

    @media ${MediaQueries.desktop} {
        max-width: ${DESKTOP_GRID_COLUMN * 6 + DESKTOP_GRID_SPACING * 4}rem;
    }

    @media ${MediaQueries.tablet} {
        padding-bottom: 10rem;
    }

    @media ${MediaQueries.phone} {
        padding-bottom: 6rem;
    }
`;

export const FeaturesContainer = styled.div`
    @media ${MediaQueries.desktop} {
        display: grid;
        grid-gap: 8rem;
        grid-template-columns: 29% 29% 29%;
        grid-row-gap: 4rem;
    }

    @media ${MediaQueries.tablet} {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-between;
        margin: 2rem 0 2rem 0;
    }
`;

export const Feature = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    @media ${MediaQueries.tablet} {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 30rem;
        margin-bottom: 8rem;
    }

    @media ${MediaQueries.phone} {
        text-align: center;
        align-items: center;
        margin-bottom: 4.5rem;
    }
`;

export const Label = styled.h4`
    padding-bottom: 2rem;
    padding-top: 2rem;
    line-height: 3.8rem;
`;
