import React from 'react';

import { whatDrivesUs } from 'data/about-us.json';

import { Container, Title, Details, Description } from './WhatDrivesUs.style';

export function WhatDrivesUs() {
    return (
        <Container>
            <Details>
                <Title>{whatDrivesUs.title}</Title>
                <Description>{whatDrivesUs.description}</Description>
            </Details>
        </Container>
    );
}
