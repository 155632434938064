import React, { useState } from 'react';

import { Colors } from 'environment';
import {
    MAIN_CONTAINER_OFFSET,
    PADDING_BETWEEN_BRANCHES,
    PHONE_ROOT_PADDING_TOP
} from 'consts/timeline';

import { Branch } from './Branch.phone';
import { Container, Root } from './Timeline.phone.style';

interface Props {
    items: { time: string; text: string }[];
}

export function Timeline({ items }: Props) {
    const [heights, setHeights] = useState<number[]>([]);

    function handleHeightChange(height: number, index: number) {
        setHeights((state) => {
            const clone = [...state];
            clone[index] = height;
            return clone;
        });
    }

    function computePosition(index: number): number {
        if (heights.length === items.length) {
            if (index === 0) return 0;
            if (index === 1) return heights[0] + PADDING_BETWEEN_BRANCHES;
            return computePosition(index - 1) + heights[index - 1] + PADDING_BETWEEN_BRANCHES;
        }

        return 0;
    }

    const height = heights.reduce((acc, item) => acc + item, 0) + MAIN_CONTAINER_OFFSET;
    return (
        <Container style={{ height }}>
            {items.map((item, index) => (
                <Branch
                    key={`branch-${index}`}
                    index={index}
                    length={items.length}
                    item={item}
                    onHeightChange={(height) => handleHeightChange(height, index)}
                    top={computePosition(index)}
                />
            ))}
            <Root style={{ top: computePosition(items.length - 1) + PHONE_ROOT_PADDING_TOP }}>
                <svg height={260} width={100} viewBox="0 0 100 100">
                    <circle
                        cx="50%"
                        cy={45}
                        r={48}
                        fill={Colors.violet}
                        stroke={Colors.magenta}
                        strokeWidth={4}
                    />
                    <circle
                        cx="50%"
                        cy="calc(50% + 71px)"
                        r={24}
                        fill={Colors.violet}
                        stroke={Colors.orange}
                        strokeWidth={4}
                    />
                    <circle
                        cx="calc(50% + 34px)"
                        cy="calc(50% + 49px)"
                        r={12}
                        fill={Colors.violet}
                        stroke={Colors.green}
                        strokeWidth={4}
                    />
                </svg>
            </Root>
        </Container>
    );
}
