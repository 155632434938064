import styled from 'styled-components';

import { MediaQueries, Colors } from 'environment';
import { DESKTOP_GRID_COLUMN, DESKTOP_GRID_SPACING, TABLET_GRID_SIZE } from 'consts';

export const Container = styled.section`
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    background-color: ${Colors.green};
`;

export const Details = styled.div`
    color: ${Colors.white};
    width: 100%;
    text-align: center;

    @media ${MediaQueries.phone} {
        padding: 6rem 2rem;
    }

    @media ${MediaQueries.tablet} {
        max-width: ${TABLET_GRID_SIZE}rem;
        padding-top: 12rem;
        padding-bottom: 6.4rem;
    }

    @media ${MediaQueries.desktop} {
        max-width: ${DESKTOP_GRID_COLUMN * 8 + DESKTOP_GRID_SPACING * 7}rem;
        padding: 12rem 0;
    }
`;

export const Title = styled.h2`
    text-align: center;
    margin-bottom: 4rem;

    @media ${MediaQueries.phone} {
        margin-bottom: 2rem;
        font-size: 3.6rem;
        line-height: 5rem;
        font-weight: 700;
    }
`;

export const Description = styled.p`
    white-space: pre-line;
`;
