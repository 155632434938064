import styled from 'styled-components';
import { Colors } from 'environment';

export const Container = styled.div`
    position: absolute;
    width: calc(100% - 4rem);

    svg {
        path {
            fill: ${Colors.violet};
        }
    }

    left: 0;

    :nth-of-type(3n + 1) {
        svg {
            path {
                stroke: ${Colors.green};
            }

            circle {
                stroke: ${Colors.green};
            }
        }
    }

    :nth-of-type(3n + 2) {
        svg {
            path {
                stroke: ${Colors.magenta};
            }

            circle {
                stroke: ${Colors.magenta};
            }
        }
    }

    :nth-of-type(3n + 3) {
        svg {
            path {
                stroke: ${Colors.orange};
            }

            circle {
                stroke: ${Colors.orange};
            }
        }
    }
`;

interface Props {
    right?: boolean;
}

export const Content = styled.div<Props>`
    position: absolute;
    top: -1.3rem;
    width: calc(100vw - 10rem - 6rem);
    text-align: left;
`;

export const Text = styled.p`
    margin-top: 2rem;
`;
