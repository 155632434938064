import styled from 'styled-components';
import { Colors, MediaQueries } from 'environment';
import { DESKTOP_GRID_SIZE, DESKTOP_GRID_COLUMN, DESKTOP_GRID_SPACING } from 'consts';

export const Floating = styled.div`
    position: relative;
    top: 1rem;
`;

export const Container = styled.section`
    background-color: ${Colors.violet};
    color: ${Colors.white};
    display: flex;
    position: relative;
    height: 100%;
    width: 100%;
    justify-content: center;

    @media ${MediaQueries.desktop} {
        padding-bottom: 5rem;
    }
`;

export const Content = styled.div`
    width: 100%;

    @media ${MediaQueries.desktop} {
        max-width: ${DESKTOP_GRID_SIZE}rem;
    }

    @media ${MediaQueries.tablet} {
        margin: 0 7.4rem;
    }
`;

export const Title = styled.h2`
    margin-bottom: 4rem;

    @media ${MediaQueries.tablet} {
        margin-top: 8rem;
    }

    @media ${MediaQueries.phone} {
        margin-top: 10rem;
    }
`;

export const Text = styled.p`
    width: 100%;
`;

export const Info = styled.div`
    width: 100%;

    @media ${MediaQueries.desktop} {
        max-width: ${DESKTOP_GRID_COLUMN * 6 + DESKTOP_GRID_SPACING * 5}rem;
        margin-top: 4rem;
    }

    @media ${MediaQueries.phone} {
        padding: 0 2rem;
    }
`;
