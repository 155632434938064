import React from 'react';

import { BackgroundImage } from 'components/ui';
import content from 'data/about-us.json';
import { useQueryContext } from 'hooks';

import { Container, Content, Description, Title } from './AboutHero.style';

export function AboutHero() {
    const {
        images: {
            aboutUs: { hero: image }
        }
    } = useQueryContext();

    return (
        <Container>
            <BackgroundImage
                mobile={image.mobile}
                tablet={image.desktop}
                desktop={image.desktop}
                alt={content.hero.alt}
            >
                <Content>
                    <Title>{content.title}</Title>
                    <Description>{content.description}</Description>
                </Content>
            </BackgroundImage>
        </Container>
    );
}
