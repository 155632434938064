import styled from 'styled-components';
import { Colors } from 'environment';
import { DESKTOP_GRID_COLUMN, DESKTOP_GRID_SPACING } from 'consts';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    position: relative;
`;

export const Branch = styled.div`
    position: relative;

    svg {
        path {
            fill: ${Colors.violet};
        }
    }

    :nth-of-type(2n) {
        left: -72px;
    }

    :nth-of-type(2n + 1) {
        left: 99px;
    }

    :nth-of-type(3n + 1) {
        svg {
            path {
                stroke: ${Colors.green};
            }

            circle {
                stroke: ${Colors.green};
            }
        }
    }

    :nth-of-type(3n + 2) {
        svg {
            path {
                stroke: ${Colors.magenta};
            }

            circle {
                stroke: ${Colors.magenta};
            }
        }
    }

    :nth-of-type(3n + 3) {
        svg {
            path {
                stroke: ${Colors.orange};
            }

            circle {
                stroke: ${Colors.orange};
            }
        }
    }
`;

interface Props {
    right?: boolean;
    left?: boolean;
}

export const Content = styled.div<Props>`
    position: absolute;
    top: -1.3rem;
    width: 38rem;
    text-align: ${({ right }) => right && 'right'};
    left: ${({ left }) => (left ? '200px' : '-400px')};
`;

export const Root = styled.div`
    position: absolute;
`;

export const Text = styled.p`
    width: 100%;
    margin-top: 2rem;
    max-width: ${DESKTOP_GRID_COLUMN * 4 + DESKTOP_GRID_SPACING * 3}rem;
`;
