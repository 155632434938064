/* TIMELINE DESKTOP CONSTS*/

export const DESKTOP_BRANCH_HEIGHT = 220;
export const DESKTOP_CONTAINER_HEIGHT_OFFSET = 120;
export const DESKTOP_ROOT_VALUE = 176;

/* TIMELINE TABLET CONSTS */

export const TABLET_BRANCH_HEIGHT = 240;
export const TABLET_CONTAINER_HEIGHT_OFFSET = 180;
export const TABLET_ROOT_PADDING_TOP = 7;

/* TIMELINE PHONE CONSTS */

export const PADDING_BETWEEN_BRANCHES = 20;
export const MAIN_CONTAINER_OFFSET = 360;
export const PHONE_ROOT_PADDING_TOP = 10;
