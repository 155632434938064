import styled from 'styled-components';
import { DESKTOP_GRID_COLUMN, DESKTOP_GRID_SPACING, TABLET_GRID_SIZE } from 'consts';
import { MediaQueries, Colors } from 'environment';

export const Container = styled.section`
    display: flex;
    position: relative;
    justify-content: center;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    color: ${Colors.violet};
    text-align: center;
    padding: 12rem 0;

    @media ${MediaQueries.desktop} {
        max-width: ${DESKTOP_GRID_COLUMN * 8 + DESKTOP_GRID_SPACING * 8}rem;
        margin: 0 2rem;
    }

    @media ${MediaQueries.tablet} {
        max-width: ${TABLET_GRID_SIZE}rem;
    }

    @media ${MediaQueries.phone} {
        padding: 6rem 2rem;
    }
`;

export const Title = styled.h1`
    padding-bottom: 4rem;

    @media ${MediaQueries.phone} {
        padding-bottom: 2rem;
    }
`;

export const Description = styled.h4`
    font-weight: normal;
`;
