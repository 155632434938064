import styled from 'styled-components';
import { Link as DefaultLink } from 'gatsby';

import { Colors, MediaQueries } from 'environment';
import { DESKTOP_GRID_SIZE, TABLET_GRID_SIZE, DESKTOP_GRID_SIZE_NO_SPACING } from 'consts';

export const Container = styled.section`
    display: flex;
    width: 100%;
    padding-bottom: 8rem;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: center;

    @media ${MediaQueries.phone} {
        padding-bottom: 0;
    }
`;

export const Content = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    color: ${Colors.violet};
    width: 100%;
    height: 100%;
    align-items: center;
    z-index: 4;

    @media ${MediaQueries.desktop} {
        max-width: ${DESKTOP_GRID_SIZE}rem;
    }

    @media ${MediaQueries.tablet} {
        max-width: ${TABLET_GRID_SIZE}rem;
        padding-top: 15rem;
    }

    @media ${MediaQueries.phone} {
        padding: 0 2rem;
        margin-top: -12rem;
    }
`;

export const TeamContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @media ${MediaQueries.desktop} {
        max-width: ${DESKTOP_GRID_SIZE_NO_SPACING}rem;
    }

    @media ${MediaQueries.phone} {
        justify-content: center;
        width: 100%;
    }
`;

export const TeamMember = styled.div`
    display: flex;
    flex-direction: column;
    width: 28rem;
    margin: 0 6rem 8rem 0;

    @media ${MediaQueries.desktop} {
        :nth-child(3n) {
            margin-right: 0;
        }
    }

    @media ${MediaQueries.tablet} {
        :nth-child(2n) {
            margin-right: 0;
        }
    }

    @media ${MediaQueries.phone} {
        margin: 0 2rem 4rem;
    }
`;

export const TeamMemberNextPlaceholder = styled.div`
    display: flex;
    align-items: center;
    width: 28rem;
    height: 32rem;
    position: relative;
    border-radius: 25px;
    box-shadow: 0 2rem 4rem -2rem rgba(77, 52, 91, 0.3);
    background-color: ${Colors.white};

    @media ${MediaQueries.desktop} {
        :nth-child(3n) {
            margin-right: 0;
        }
    }

    @media ${MediaQueries.tablet} {
        :nth-child(2n) {
            margin-right: 0;
        }
    }

    @media ${MediaQueries.phone} {
        margin: 0 2rem 4rem;
    }
`;

export const BorderSVG = styled.img`
    position: absolute;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    z-index: -1;
    left: -2px;
`;

export const LinkToCareers = styled(DefaultLink)`
    cursor: pointer;
    font-size: 2.4rem;
    font-weight: 500;
    text-align: center;
    color: ${Colors.violet};
`;

export const PictureContainer = styled.div`
    position: relative;
    height: 32rem;
`;

export const Picture = styled.img`
    height: 100%;
    object-fit: contain;
    border-radius: 25px;
    box-shadow: 0 2rem 4rem -2rem rgba(77, 52, 91, 0.3);
`;

interface PictureCircleProps {
    bgColor: string;
}

export const PictureCircle = styled.div<PictureCircleProps>`
    position: absolute;
    width: 4rem;
    height: 4rem;
    background-color: ${({ bgColor }) => bgColor};
    border-radius: 50%;
    bottom: 0;
`;

export const Name = styled.h4`
    text-align: center;
    padding: 2rem 0rem 1rem 0;
    line-height: 3.8rem;
`;

export const Position = styled.p`
    text-align: center;
`;

export const Title = styled.h2`
    padding-bottom: 12rem;

    @media ${MediaQueries.phone} {
        text-align: center;
        font-size: 3.6rem;
        line-height: 5rem;
        font-weight: bold;
        padding-bottom: 6rem;
    }
`;

export const CirclesContainer = styled.div`
    position: absolute;
    z-index: 2;
    width: 100%;

    @media ${MediaQueries.desktop} {
        svg:nth-child(1) {
            width: 100%;
            transform: translate(-53%, 22%);
        }
        svg:nth-child(2) {
            width: 100%;
            transform: translate(47%, 50%);
        }
    }

    @media ${MediaQueries.tablet} {
        height: auto;
        bottom: 0;
        max-width: ${TABLET_GRID_SIZE}rem;
        svg {
            width: 100%;
            transform: translate(52%, -5%);
        }
    }
`;
