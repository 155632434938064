import React from 'react';

import { ourValues } from 'data/about-us.json';
import { useQueryContext } from 'hooks';
import { BackgroundImage } from 'components/ui';

import {
    Container,
    Content,
    Description,
    Title,
    TextContainer,
    FeaturesContainer,
    Feature,
    Label
} from './OurValues.style';

export function OurValues() {
    const {
        images: {
            aboutUs: {
                ourValues: { image }
            }
        }
    } = useQueryContext();

    return (
        <Container>
            <BackgroundImage
                mobile={image.mobile}
                tablet={image.tablet}
                desktop={image.desktop}
                alt={ourValues.alt}
            >
                <Content>
                    <TextContainer>
                        <Title>{ourValues.title}</Title>
                        <Description>{ourValues.description}</Description>
                    </TextContainer>

                    <FeaturesContainer>
                        {ourValues.features.map((item, index) => {
                            const Icon = require(`../../../../static/images/${item.icon}`);
                            return (
                                <Feature key={`feature-container-${index}`}>
                                    <Icon />
                                    <Label>{item.label}</Label>
                                    <p>{item.text}</p>
                                </Feature>
                            );
                        })}
                    </FeaturesContainer>
                </Content>
            </BackgroundImage>
        </Container>
    );
}
