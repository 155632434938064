import styled from 'styled-components';
import { Colors, MediaQueries } from 'environment';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    position: relative;

    @media ${MediaQueries.desktop} {
        top: 2rem;
    }
`;

export const Branch = styled.div`
    position: relative;

    svg {
        path {
            fill: ${Colors.violet};
        }
    }

    :nth-of-type(2n) {
        left: -72px;
    }

    :nth-of-type(2n + 1) {
        left: 99px;
    }

    :nth-of-type(3n + 1) {
        svg {
            path {
                stroke: ${Colors.green};
            }

            circle {
                stroke: ${Colors.green};
            }
        }
    }

    :nth-of-type(3n + 2) {
        svg {
            path {
                stroke: ${Colors.magenta};
            }

            circle {
                stroke: ${Colors.magenta};
            }
        }
    }

    :nth-of-type(3n + 3) {
        svg {
            path {
                stroke: ${Colors.orange};
            }

            circle {
                stroke: ${Colors.orange};
            }
        }
    }
`;

interface Props {
    right?: boolean;
}

export const Content = styled.div<Props>`
    position: absolute;
    top: -1.3rem;
    width: 22rem;
    text-align: ${({ right }) => right && 'right'};
`;

export const Root = styled.div`
    position: absolute;
`;

export const Text = styled.p`
    margin-top: 2rem;
`;
