import React from 'react';

import { AboutHero, History, WhatDrivesUs, OurValues, MeetTheTeam } from 'components/about';
import seo from 'data/seo.json';
import { SEO } from 'components/ui';

export default function AboutUs() {
    return (
        <>
            <SEO
                title={seo.aboutUsTitle}
                description={seo.aboutUsDescription}
                ogTitle={seo.aboutUsOgTitle}
                ogDescription={seo.aboutUsOgDescription}
                ogImage={seo.aboutUsOgImage}
            />
            <AboutHero />
            <History />
            <WhatDrivesUs />
            <OurValues />
            <MeetTheTeam />
        </>
    );
}
