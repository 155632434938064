import React from 'react';

import { RichText } from 'components/ui/RichText';
import { history } from 'data/about-us.json';
import { useMediaQuery } from 'hooks';
import { Icons, MediaQueries } from 'environment';

import { Timeline as DesktopTimeline } from './Timeline.desktop';
import { Timeline as TabletTimeline } from './Timeline.tablet';
import { Timeline as PhoneTimeline } from './Timeline.phone';
import { Container, Content, Floating, Info, Text, Title } from './History.style';

export function History() {
    const isPhone = useMediaQuery(MediaQueries.phone);
    const isTablet = useMediaQuery(MediaQueries.tablet);
    const isDesktop = useMediaQuery(MediaQueries.desktop);

    return (
        <section>
            <Floating>
                <Icons.Waves />
            </Floating>
            <Container>
                <Content>
                    <Info>
                        <Title>{history.title}</Title>
                        <RichText>
                            <Text>{history.description}</Text>
                        </RichText>
                    </Info>
                    {isDesktop && <DesktopTimeline items={history.timeline} />}
                    {isTablet && <TabletTimeline items={history.timeline} />}
                    {isPhone && <PhoneTimeline items={history.timeline} />}
                </Content>
            </Container>
        </section>
    );
}
