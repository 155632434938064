import React from 'react';

import {
    DESKTOP_BRANCH_HEIGHT,
    DESKTOP_CONTAINER_HEIGHT_OFFSET,
    DESKTOP_ROOT_VALUE
} from 'consts/timeline';
import { Colors } from 'environment';
import { computeBezierCurve } from 'helpers';

import { Container, Content, Branch, Root, Text } from './Timeline.desktop.style';

interface Props {
    items: { time: string; text: string }[];
}

export function Timeline({ items }: Props) {
    return (
        <Container
            style={{
                height: items.length * DESKTOP_BRANCH_HEIGHT - DESKTOP_CONTAINER_HEIGHT_OFFSET
            }}
        >
            {items.map((item, index) => {
                let d = `M7,12 L100,12 ${computeBezierCurve(98, 12, 174, 85)}`;

                if (index % 2 === 0) {
                    d = `M168,12 L74,12 ${computeBezierCurve(78, 12, 3, 85, true)}`;
                }

                if (index !== items.length - 1) {
                    d = `${d} l0,165`;
                }

                return (
                    <Branch
                        key={`branch-${index}`}
                        style={{
                            top:
                                index === items.length - 1
                                    ? -((index + 1) * 86) + items.length * 40 + 3
                                    : -((index + 1) * 86) + items.length * 40
                        }}
                    >
                        <svg height={index === items.length - 1 ? 80 : 260} width={200}>
                            <path d={d} strokeWidth={4} />
                            <circle
                                cx={index % 2 === 0 ? 168 : 12}
                                cy={12}
                                r={7.5}
                                fill={Colors.violet}
                                stroke={Colors.green}
                                strokeWidth={4}
                            />
                        </svg>
                        <Content right={index % 2 !== 0} left={index % 2 === 0}>
                            <h3>{item.time}</h3>
                            <Text>{item.text}</Text>
                        </Content>
                    </Branch>
                );
            })}
            <Root style={{ top: items.length * DESKTOP_ROOT_VALUE }}>
                <svg height={160} width={100} viewBox="0 0 100 100">
                    <circle
                        cx="50%"
                        cy={23}
                        r={48}
                        fill={Colors.violet}
                        stroke={Colors.magenta}
                        strokeWidth={4}
                    />
                    <circle
                        cx="50%"
                        cy="calc(50% + 49px)"
                        r={24}
                        fill={Colors.violet}
                        stroke={Colors.orange}
                        strokeWidth={4}
                    />
                    <circle
                        cx="calc(50% + 34px)"
                        cy="calc(50% + 27px)"
                        r={12}
                        fill={Colors.violet}
                        stroke={Colors.green}
                        strokeWidth={4}
                    />
                </svg>
            </Root>
        </Container>
    );
}
